<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin" v-if="categories.length > 0">
            <div uk-grid class="uk-grid-small">
                <div>
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'question')" type="text" placeholder="Search by question">
                    </div>
                </div>
                <div>
                    <div class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" v-model="meta.test_category_id" @change="(e) => changeMeta(e, 'test_category_id')">
                            <option :value="''">Select category</option>
                            <template v-for="(category, x) in categories" >
                                <option :value="category._id" :key="x">{{category.name}}</option>
                            </template>
                        </select>
                    </div>
                </div>
                <div class="uk-inline">
                  <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showCreateModal"
                  v-if="consist(user_cred.roles, ['admin-bd', 'qwe123'])">Create</button>
                </div>
            </div>
        </div>
        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-center">
                    <thead>
                        <tr>
                            <th class="uk-text-center">Question</th>
                            <th class="uk-text-center">Category</th>
                            <th class="uk-text-center">Options</th>
                            <th class="uk-text-center">Valid Answer</th>
                            <th class="uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="test_questions.docs.length>0">
                        <tr v-for="(question, i) in test_questions.docs" :key="i">
                            <td>{{question.question}}</td>
                            <td>{{question.test_category_id.name}}</td>
                            <td>{{question.options}}</td>
                            <td>{{question.valid_answer}}</td>
                            <td>
                                <button
                                  style="width:100px; cursor:pointer; margin-bottom: 10px; height:30px"
                                  class="uk-button-default"
                                  @click="showPreviewModal(question)">
                                  Preview
                                </button><br>
                                <button
                                  style="width:100px; cursor:pointer; margin-bottom: 10px; height:30px"
                                  class="uk-button-primary"
                                  @click="editTestQuestion(question)"
                                  v-if="consist(user_cred.roles, ['admin-bd', 'qwe123'])">
                                  Edit
                                </button><br>
                                <button
                                  style="width:100px; cursor:pointer; height:30px"
                                  class="uk-button-danger"
                                  @click="showDeleteModal(question)"
                                  v-if="consist(user_cred.roles, ['admin-bd', 'qwe123'])">
                                  Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="test_questions.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="deleteModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
                <h2 class="uk-modal-title">Delete Test Question</h2>
                <p>Are you sure to delete this test question ?
                    <br>Question : {{question.question}}
                    <br>Category : {{question.test_category_id.name}}
                    <br>Options : {{question.options}}
                    <br>Valid Answer : {{question.valid_answer}}
                </p>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-danger" type="button" @click="deleteQuestion(question._id)">Delete</button>
                </p>
            </div>
        </div>

        <div id="formQuestionModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideCreateModal"></button>
            <div class="uk-modal-header">
              <h2 v-if="formIsEdit" class="uk-modal-title">Edit Form</h2>
              <h2 v-else class="uk-modal-title">Create Form</h2>
            </div>
            <div class="uk-modal-body">

              <div class="uk-margin">
                <label class="uk-form-label">Test Category <b class="uk-text-danger">*</b></label>
                <multiselect
                  v-model="form.test_category_id"
                  placeholder="Select Category"
                  label="name"
                  name="test_category_id"
                  track-by="id"
                  v-validate="'required'"
                  :options="categories">
                </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('test_category_id')">{{ errors.first('test_category_id') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Question <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('question')}"
                  name="question"
                  type="text"
                  v-model="form.question"
                  v-validate="'required'"
                  placeholder="Question">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('question')">{{ errors.first('question') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label uk-margin-right">Quesstion Image </label><br>
                <img v-if="form.image != ''" :src="`${form.image}`" alt="" class="uk-margin-bottom" width="200">
                    <input
                        name="question_image"
                        type="file"
                        ref="question_image"
                        class="uk-input"
                        placeholder="Upload Image">
              </div>

              <div class="uk-margin">
                <label class="uk-form-label uk-margin-small-right">Type:</label>
                <label class="uk-form-label uk-margin-small-right">
                  <input
                    class="uk-radio"
                    name="type"
                    type="radio"
                    v-bind:value="1"
                    v-model="form.type"
                  >
                    Single Answer
                </label>
                <label class="uk-form-label uk-margin-small-right">
                  <input
                    class="uk-radio"
                    name="type"
                    type="radio"
                    v-bind:value="2"
                    v-model="form.type"
                  >
                    Multiple Answer
                </label><b class="uk-text-danger">*</b>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Answer Options <b class="uk-text-danger">*</b>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('option1') || errors.has('option2')"> At least fill 2 first options!</span></label>
                <input
                  class="uk-input uk-margin-small-bottom"
                  :class="{'uk-form-danger': errors.has('option1')}"
                  name="option1"
                  type="text"
                  v-model="ans_opt.opt1.answer"
                  v-validate="'required'"
                  placeholder="Option 1">
                <input
                  class="uk-input uk-margin-small-bottom"
                  :class="{'uk-form-danger': errors.has('option2')}"
                  name="option2"
                  type="text"
                  v-model="ans_opt.opt2.answer"
                  v-validate="'required'"
                  placeholder="Option 2">
                <input
                  class="uk-input uk-margin-small-bottom"
                  name="options"
                  type="text"
                  v-model="ans_opt.opt3.answer"
                  placeholder="Option 3 (optional)">
                <input
                  class="uk-input uk-margin-small-bottom"
                  name="options"
                  type="text"
                  v-model="ans_opt.opt4.answer"
                  placeholder="Option 4 (optional)">
                <input
                  class="uk-input uk-margin-small-bottom"
                  name="options"
                  type="text"
                  v-model="ans_opt.opt5.answer"
                  placeholder="Option 5 (optional)">
              </div>

              <div class="uk-margin" v-if="form.type==1">
                <label class="uk-form-label uk-margin-small-right">Valid Answer </label><b class="uk-text-danger">*</b>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('valid_answer')"> Please choose 1 valid answer!</span>
                <br><label class="uk-form-label uk-margin-small-right" v-if="ans_opt.opt1.answer!=''">
                  <input
                    class="uk-radio"
                    name="valid_answer"
                    type="radio"
                    v-bind:value="ans_opt.opt1.answer"
                    v-model="tmp_answer"
                    v-validate="'required'"
                  >
                    {{ans_opt.opt1.answer}}<br>
                </label>
                <label class="uk-form-label uk-margin-small-right" v-if="ans_opt.opt2.answer!=''">
                  <input
                    class="uk-radio"
                    name="valid_answer"
                    type="radio"
                    v-bind:value="ans_opt.opt2.answer"
                    v-model="tmp_answer"
                    v-validate="'required'"
                  >
                    {{ans_opt.opt2.answer}}<br>
                </label>
                <label class="uk-form-label uk-margin-small-right" v-if="ans_opt.opt3.answer!=''">
                  <input
                    class="uk-radio"
                    name="valid_answer"
                    type="radio"
                    v-bind:value="ans_opt.opt3.answer"
                    v-model="tmp_answer"
                    v-validate="'required'"
                  >
                    {{ans_opt.opt3.answer}}<br>
                </label>
                <label class="uk-form-label uk-margin-small-right" v-if="ans_opt.opt4.answer!=''">
                  <input
                    class="uk-radio"
                    name="valid_answer"
                    type="radio"
                    v-bind:value="ans_opt.opt4.answer"
                    v-model="tmp_answer"
                    v-validate="'required'"
                  >
                    {{ans_opt.opt4.answer}}<br>
                </label>
                <label class="uk-form-label uk-margin-small-right" v-if="ans_opt.opt5.answer!=''">
                  <input
                    class="uk-radio"
                    name="valid_answer"
                    type="radio"
                    v-bind:value="ans_opt.opt5.answer"
                    v-model="tmp_answer"
                    v-validate="'required'"
                  >
                    {{ans_opt.opt5.answer}}<br>
                </label>
              </div>

              <div class="uk-margin" v-if="form.type==2">
                <label class="uk-form-label uk-margin-small-right">Valid Answer </label><b class="uk-text-danger">*</b>
                <span class="uk-text-small uk-text-danger" v-if="unchecked_box > 3"> Please choose at least 2 valid answer!</span>
                <br><label class="uk-form-label uk-margin-small-right" v-show="ans_opt.opt1.answer!=''">
                  <input
                    class="uk-checkbox"
                    name="opt1"
                    type="checkbox"
                    v-model="ans_opt.opt1.check"
                  >
                    {{ans_opt.opt1.answer}}<br>
                </label>
                <label class="uk-form-label uk-margin-small-right" v-show="ans_opt.opt2.answer!=''">
                  <input
                    class="uk-checkbox"
                    name="opt2"
                    type="checkbox"
                    v-model="ans_opt.opt2.check"
                  >
                    {{ans_opt.opt2.answer}}<br>
                </label>
                <label class="uk-form-label uk-margin-small-right" v-show="ans_opt.opt3.answer!=''">
                  <input
                    class="uk-checkbox"
                    name="opt3"
                    type="checkbox"
                    v-model="ans_opt.opt3.check"
                  >
                    {{ans_opt.opt3.answer}}<br>
                </label>
                <label class="uk-form-label uk-margin-small-right" v-show="ans_opt.opt4.answer!=''">
                  <input
                    class="uk-checkbox"
                    name="opt4"
                    type="checkbox"
                    v-model="ans_opt.opt4.check"
                  >
                    {{ans_opt.opt4.answer}}<br>
                </label>
                <label class="uk-form-label uk-margin-small-right" v-show="ans_opt.opt5.answer!=''">
                  <input
                    class="uk-checkbox"
                    name="opt5"
                    type="checkbox"
                    v-model="ans_opt.opt5.check"
                  >
                    {{ans_opt.opt5.answer}}<br>
                </label>
              </div>

            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary" type="button" @click="saveQuestion">Save</button>
            </div>
          </div>
        </div>

        <div id="previewModal" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hidePreviewModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Question Preview</h2>
                </div>
                <div class="uk-modal-body">
                    <img :src="`${question.image}`" alt="" width="300" v-if="question.image">
                    <p>{{question.question}}</p>

                    <template v-if="question.type==1">
                        <div class="uk-margin" v-for="(option,index) in question.options" :key="index">
                            <input
                                class="uk-radio"
                                name="option"
                                type="radio"
                            >
                                {{option}}<br>
                        </div>
                    </template>

                    <template v-if="question.type==2">
                        <div class="uk-margin" v-for="(option,index) in question.options" :key="index">
                            <input
                                class="uk-checkbox"
                                name="option"
                                type="checkbox"
                            >
                                {{option}}<br>
                        </div>
                    </template>

                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" @click="hidePreviewModal">Ok</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    data() {
        return {
            user_cred: getUserLogin(),
            test_questions: {docs:[]},
            question: {test_category_id: {}},
            categories: [],
            meta: {
                limit: 100,
                page: 1,
                question: '',
                test_category_id: ''
            },
            form: {
                question: '',
                options: [],
                valid_answer: [],
                type: 1,
                image: null,
                test_category_id: '',
            },
            ans_opt: {
                opt1: {answer: '', check: false},
                opt2: {answer: '', check: false},
                opt3: {answer: '', check: false},
                opt4: {answer: '', check: false},
                opt5: {answer: '', check: false},
            },
            tmp_answer: '',
            isLoading: false,
            isFetching: false,
            formIsEdit: false,
            unchecked_box: 0,
        };
    },
    components: {
        Pagination,
        EmptyTable,
    },
    watch: {
        meta() {
            this.setTestQuestionMeta();
        },
    },
    async mounted() {
        await this.setTestQuestionMeta();
        await this.getCategories();
    },
    methods: {
        ...mapActions({
            getTestCategoryListsNonPaginate: 'test_category/getTestCategoryListsNonPaginate',
            getTestQuestionLists: 'test_question/getTestQuestionLists',
            updateTestQuestion: 'test_question/updateTestQuestion',
            deleteTestQuestion: 'test_question/deleteTestQuestion',
            createTestQuestion: 'test_question/createTestQuestion',
            uploadImageApi: 'file/uploadImage'
        }),
        checkAnswerBox(){
            this.unchecked_box = 0;
            let string = 'opt';
            for (let index = 1; index < 6; index++) {
                let opt_index = string + index;
                if (!this.ans_opt[opt_index].check) {
                    this.unchecked_box += 1;
                }
            }
        },
        async setTestQuestionMeta() {
            this.test_questions = await this.getTestQuestionLists(this.meta);
        },
        async getCategories() {
            this.categories = await this.getTestCategoryListsNonPaginate();
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async hideCreateModal() {
            await window.UIkit.modal('#formQuestionModal').hide();
            this.formIsEdit = false;
            this.resetForm();
        },
        editTestQuestion(question) {
            this.formIsEdit = true;
            this.fillForm(question);
            this.showCreateModal();
        },
        async saveQuestion() {
            try {
                const validate = await this.$validator.validateAll();
                this.checkAnswerBox();
                if (!validate || this.$validator.errors.any()) return;
                if (this.form.type == 2) {
                    if (this.unchecked_box > 3) return;
                }

                this.isLoading = true;

                this.form.test_category_id = this.form.test_category_id._id;
                this.optionsSave();
                this.answerSave();

                await this.uploadImage();

                const response = this.formIsEdit
                    ? await this.updateTestQuestion(this.form)
                    : await this.createTestQuestion(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Test Question saved!');
                    this.setTestQuestionMeta();
                    this.hideCreateModal();
                } else {
                    notificationDanger('Failed to save test question.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async uploadImage() {
            var files = this.$refs.question_image.files;
            let formData = new FormData();

            for( var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImageApi({ folder: 'assessment-image', category: 'assessment', data: formData });
                this.form.image = result.file_url;
            }
        },
        optionsSave(){
            if (this.ans_opt.opt1.answer!='') {
                this.form.options.push(this.ans_opt.opt1.answer);
            }
            if (this.ans_opt.opt2.answer!='') {
                this.form.options.push(this.ans_opt.opt2.answer);
            }
            if (this.ans_opt.opt3.answer!='') {
                this.form.options.push(this.ans_opt.opt3.answer);
            }
            if (this.ans_opt.opt4.answer!='') {
                this.form.options.push(this.ans_opt.opt4.answer);
            }
            if (this.ans_opt.opt5.answer!='') {
                this.form.options.push(this.ans_opt.opt5.answer);
            }
        },
        answerSave(){
            if (this.form.type==1) {
                this.form.valid_answer.push(this.tmp_answer);
            } else {
                this.form.valid_answer = [];
                if (this.ans_opt.opt1.check) {
                    this.form.valid_answer.push(this.ans_opt.opt1.answer);
                }
                if (this.ans_opt.opt2.check) {
                    this.form.valid_answer.push(this.ans_opt.opt2.answer);
                }
                if (this.ans_opt.opt3.check) {
                    this.form.valid_answer.push(this.ans_opt.opt3.answer);
                }
                if (this.ans_opt.opt4.check) {
                    this.form.valid_answer.push(this.ans_opt.opt4.answer);
                }
                if (this.ans_opt.opt5.check) {
                    this.form.valid_answer.push(this.ans_opt.opt5.answer);
                }
            }
        },
        async showCreateModal() {
            await window.UIkit.modal('#formQuestionModal').show();
        },
        fillForm(question) {
            try {
                this.form.id = question._id;
                this.form.test_category_id = question.test_category_id;
                this.form.question = question.question;
                this.form.type = question.type;
                this.form.image = question.image;
                this.fillOptions(question);
                this.fillAnswer(question);
            } catch (err) {
                notificationDanger(err);
            }
        },
        fillOptions(question){
            for (const opt of question.options) {
                let string_tmp = 'opt';
                for (let index = 1; index < 6; index++) {
                    let opt_index = string_tmp + index;
                    if (this.ans_opt[opt_index].answer=='') {
                        this.ans_opt[opt_index].answer = opt;
                        break;
                    }
                }
            }
        },
        fillAnswer(question){
            if (question.type == 1) {
                this.tmp_answer = question.valid_answer[0];
            } else {
                for (const db_ans of question.valid_answer) {
                    let string_tmp = 'opt';
                    for (let index = 1; index < 6; index++) {
                        let opt_index = string_tmp + index;
                        if (this.ans_opt[opt_index].answer == db_ans) {
                            this.ans_opt[opt_index].check = true;
                            break;
                        }
                    }
                }
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.form = {
                question: '',
                options: [],
                valid_answer: [],
                type: 1,
                image: '',
                test_category_id: '',
            };
            this.ans_opt = {
                opt1: {answer: '', check: false},
                opt2: {answer: '', check: false},
                opt3: {answer: '', check: false},
                opt4: {answer: '', check: false},
                opt5: {answer: '', check: false},
            };
            this.tmp_answer = '';
            this.$refs.question_image.value = '';
            this.$validator.reset();
        },
        async showDeleteModal(question){
            this.question = question;
            await window.UIkit.modal('#deleteModal').show();
        },
        async hideDeleteModal(){
            await window.UIkit.modal('#deleteModal').hide();
            this.question = {test_category_id: {}};
        },
        async showPreviewModal(question){
            this.question = question;
            await window.UIkit.modal('#previewModal').show();
        },
        async hidePreviewModal(){
            await window.UIkit.modal('#previewModal').hide();
            this.question = {test_category_id: {}};
        },
        async deleteQuestion(id){
            try {
                const response = await this.deleteTestQuestion(id);
                if (response && response.status === 'OK') {
                    notificationSuccess('Test Question deleted!');
                    this.setTestQuestionMeta();
                    this.hideDeleteModal();
                } else {
                    notificationDanger('Failed to delete test question.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    },
};
</script>
